import React from 'react'
import { IconsProps } from 'msp-integrations'

const BellEmptyIcon = (props: IconsProps) => {
  return (
    <svg
      width='12'
      height='14'
      viewBox='0 0 12 14'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.99317 1.79002C5.99856 1.79108 6.00387 1.79234 6.0091 1.79379C6.75507 1.83689 7.63834 2.06933 8.36473 2.75032C9.18445 3.51881 9.76816 4.82428 9.76816 6.96146C9.76816 8.47002 9.95671 9.49952 10.1415 10.1464C10.234 10.47 10.3256 10.6981 10.3924 10.843C10.4259 10.9154 10.4532 10.9671 10.4713 10.9995C10.4804 11.0157 10.4872 11.0271 10.4913 11.0339L10.4955 11.0406L10.496 11.0414L10.7175 11.3737H0.97168L1.1932 11.0414L1.19371 11.0406L1.19789 11.0339C1.20204 11.0271 1.20883 11.0157 1.21791 10.9995C1.23605 10.9671 1.26333 10.9154 1.29677 10.843C1.36364 10.6981 1.45523 10.47 1.54766 10.1464C1.7325 9.49952 1.92105 8.47002 1.92105 6.96146C1.92105 4.82428 2.50476 3.51881 3.32448 2.75032C4.03092 2.08803 4.88574 1.85 5.61834 1.79778V0.97168H5.99317V1.79002ZM5.84461 2.21588C5.13455 2.23337 4.29306 2.42973 3.6176 3.06298C2.9108 3.7256 2.34962 4.90072 2.34962 6.96146C2.34962 8.50594 2.15654 9.5754 1.95975 10.2642C1.87568 10.5584 1.79101 10.7829 1.72058 10.9451H9.96863C9.8982 10.7829 9.81353 10.5584 9.72947 10.2642C9.53267 9.5754 9.33959 8.50594 9.33959 6.96146C9.33959 4.90072 8.77841 3.7256 8.07162 3.06298C7.39615 2.42973 6.55466 2.23337 5.84461 2.21588Z'
        strokeWidth='0.3'
      />
      <path d='M4.59469 12.6371H7.09469V13.0657H4.59469V12.6371Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.99317 1.79002C5.99856 1.79108 6.00387 1.79234 6.0091 1.79379C6.75507 1.83689 7.63834 2.06933 8.36473 2.75032C9.18445 3.51881 9.76816 4.82428 9.76816 6.96146C9.76816 8.47002 9.95671 9.49952 10.1415 10.1464C10.234 10.47 10.3256 10.6981 10.3924 10.843C10.4259 10.9154 10.4532 10.9671 10.4713 10.9995C10.4804 11.0157 10.4872 11.0271 10.4913 11.0339L10.4955 11.0406L10.496 11.0414L10.7175 11.3737H0.97168L1.1932 11.0414L1.19371 11.0406L1.19789 11.0339C1.20204 11.0271 1.20883 11.0157 1.21791 10.9995C1.23605 10.9671 1.26333 10.9154 1.29677 10.843C1.36364 10.6981 1.45523 10.47 1.54766 10.1464C1.7325 9.49952 1.92105 8.47002 1.92105 6.96146C1.92105 4.82428 2.50476 3.51881 3.32448 2.75032C4.03092 2.08803 4.88574 1.85 5.61834 1.79778V0.97168H5.99317V1.79002ZM5.84461 2.21588C5.13455 2.23337 4.29306 2.42973 3.6176 3.06298C2.9108 3.7256 2.34962 4.90072 2.34962 6.96146C2.34962 8.50594 2.15654 9.5754 1.95975 10.2642C1.87568 10.5584 1.79101 10.7829 1.72058 10.9451H9.96863C9.8982 10.7829 9.81353 10.5584 9.72947 10.2642C9.53267 9.5754 9.33959 8.50594 9.33959 6.96146C9.33959 4.90072 8.77841 3.7256 8.07162 3.06298C7.39615 2.42973 6.55466 2.23337 5.84461 2.21588Z'
        strokeWidth='0.3'
        strokeLinecap='round'
      />
      <path
        d='M4.59469 12.6371H7.09469V13.0657H4.59469V12.6371Z'
        strokeWidth='0.3'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default BellEmptyIcon
